import React from 'react';
import { ImWhatsapp } from "react-icons/im";
import { FaTelegram } from "react-icons/fa";
import { Link } from 'react-router-dom';



const FloatingButton = () => {
    return (
        <div className='fixed flex flex-col gap-2 sm:gap-2 bottom-2 right-3 md:flex-row sm:flex-row sm:right-10 md:right-5 md:bottom-5'>
            <Link to="https://wa.me/251924473333" target='_blank'>

                <button className=" p-3 text-white bg-[#25D366] rounded-full shadow-lg">
                    <ImWhatsapp className='w-6 h-6 md:h-6 md:w-6 sm:h-8 sm:w-8 lg:h-8 lg:w-8' />
                </button>
            </Link>
            <Link to="https://t.me/bilihchereta" target='_blank'>
                <button className=" p-3 text-white bg-[#34B7F1] rounded-full shadow-lg" >
                    <FaTelegram className='w-6 h-6 md:h-6 md:w-6 sm:h-8 sm:w-8 lg:h-8 lg:w-8' />
                </button>
            </Link>
        </div>

    );
};

export default FloatingButton;
