import React from 'react'
import Hero from '../components/Hero'
import Features from '../components/Features'
import Testimonial from '../components/Testimonial'
import CallToAction from '../components/CallToAction'
import AppDownload from '../components/AppDownload'
import FloatingButton from '../components/Floatingbutton'

export default function HomePage() {
    return (
        <div>
            <Hero />
            <Features />
            <AppDownload />
            <Testimonial />
            <CallToAction />
            <FloatingButton />

        </div>
    )
}
