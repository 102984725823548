import React, { Fragment } from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Features from '../components/Features'
import Testimonial from '../components/Testimonial'
import CallToAction from '../components/CallToAction'
import Footer from '../components/Footer'
import { Outlet } from 'react-router-dom'

export const DefaultLayout = () => {
    return (
        <Fragment>
            <div className='w-full overflow-hidden'>
                <Navbar />

                <div className="mt-[96px] lg:mt-[89px]">
                    <Outlet />
                </div>

                <Footer />
            </div>

        </Fragment>
    )
}
