import { Link } from 'react-router-dom';

import illustrationIntro from '../assets/images/Google-Pixel-7-Pro-Mockup.png';

const Hero = () => {
  return (
    <section id='hero'>
      {/* Flex Container */}
      <div className='container flex flex-col-reverse items-center px-4 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row'>
        {/* Left Item */}
        <div className='flex flex-col mb-32 space-y-12 md:w-1/2'>
          <h1 className='max-w-md text-4xl font-bold text-center md:text-5xl md:text-left'>
            Of Which Business business owners
            <h4 className='text-4xl text-brightRed'>would you Love to Know </h4>
          </h1>
          <p className='max-w-sm text-center text-darkGrayishBlue md:text-left'>
            Are you
          </p>
          <div className='flex justify-center md:justify-start'>
            <Link
              to='#'
              className='p-3 px-6 pt-2 text-white rounded-full bg-brightRed baseline hover:bg-brightRedLight'
            >
              Download Now
            </Link>
          </div>
        </div>
        {/* Image */}
        <div className='md:w-1/2'>
          <img src={illustrationIntro} alt='' />
        </div>
      </div>
    </section>
  );
};

export default Hero;
