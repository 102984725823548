import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features.jsx';
import Testimonial from './components/Testimonial';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import { DefaultLayout } from './layouts/DefaultLayout.jsx';
import HomePage from './pages/HomePage.jsx';

function App() {
  return (
    <Routes>
      <Route path="" element={<DefaultLayout />}>
        <Route index element={<HomePage />} />
        <Route path="pricing" element={<HomePage />} />
      </Route>
    </Routes>
  );
}

export default App;
