import React from 'react';
import GooglePlay from "../assets/images/Google-play.png";
import AppStore from "../assets/images/App-store.png";
import Mockup from "../assets/images/Mock-up.png"
import { Link } from 'react-router-dom';


function AppDownload() {
    return (
        <div>
            <section class="text-gray-600 body-font">
                <div className='flex justify-center w-full'>
                    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col justify-between w-full  items-center  justify-center">
                        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                            <img class="object-cover object-center rounded" alt="hero" src={Mockup} />
                        </div>
                        <div class="lg:flex-grow md:w-1/2 lg:pl-0 md:pl-0 flex flex-col  md:items-center md:text-left items-center text-center">
                            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Nigid Listing</h1>
                            <p class="mb-8 leading-relaxed max-w-2xl">Chillwave portland ugh, knausgaard fam polaroid iPhone. Man braid swag typewriter affogato, hella selvage wolf narwhal dreamcatcher.</p>

                            <div class="flex lg:flex-row md:flex-row max-w-sm xl:max-w-md">
                                <div class="lg:ml-4 md:ml-0 ml-4 md:mt-4 mt-0 lg:mt-0 max-h-3">
                                    <Link to='#'><img src={GooglePlay} /> </Link>

                                </div>
                                <div class="items-center lg:ml-4 md:ml-2 ml-4 md:mt-4 mt-0 lg:mt-0 max-h-3">
                                    <Link to='#'><img src={AppStore} /> </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AppDownload