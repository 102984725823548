import { useState } from 'react';
import { Link } from 'react-router-dom';

import companyLogo from '../assets/images/logo.svg';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className='relative w-screen mx-auto '>
      {/* Flex Container */}
      <div className='fixed w-full z-30 bg-opacity-90 bg-white h-[80px] flex items-center backdrop-blur-0 shadow-sm '>
        <div className='fixed flex items-center justify-between w-screen pr-[12%] sm:pl-[8%] md:pl-[0%] lg:pl-[2%] xl:pl-[10%]'>
          {/* Logo */}
          <div className='pt-2'>
            <img src={companyLogo} alt='' className='h-[40px]' />
          </div>
          {/* Menu Items */}
          <div className='hidden space-x-6 md:flex'>
            <Link to='#' className='hover:text-darkGrayishBlue'>
              <span className='text-base font-semibold font-ui-monospace hover:text-brightRed'>Download Now</span>
            </Link>

          </div>

          <button
            className={
              toggleMenu
                ? 'open block hamburger md:hidden focus:outline-none'
                : 'block hamburger md:hidden focus:outline-none'
            }
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            <span className='hamburger-top'></span>
            <span className='hamburger-middle'></span>
            <span className='hamburger-bottom'></span>
          </button>
        </div>

        {/* Mobile Menu */}
        <div className='md:hidden'>
          <div
            className={
              toggleMenu
                ? 'absolute flex flex-col items-center self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
                : 'absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
            }
          >
            {/* <Link to='#'>Pricing</Link>
            <Link to='#'>Product</Link>
            <Link to='#'>About Us</Link>
            <Link to='#'>Careers</Link>
            <Link to='#'>Community</Link> */}
            <Link to='#' className='hover:text-darkGrayishBlue'>
              <span className='text-base font-semibold font-ui-monospace hover:text-brightRed'>Download Now</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
